import request_static from '@/utils/request_static'

export function get_static_file(file_name) {//获取，json
    //pages/index_page.json
    return request_static({
        url: file_name,
        method: 'get',
    })
}

