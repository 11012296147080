import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'home' }, // 将根路径重定向到 /home
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/charge',
    redirect: { name: 'ChargeVip' } // 将根路径重定向到 /home
  },
  {
    path: '/index',
    name: 'index',
    component: IndexView,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/home')
      },
      {
        path: '/category',
        name: 'category',
        component: () => import(/* webpackChunkName: "about" */ '../views/home/category')
      },
      {
        path: '/chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "about" */ '../views/chat')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "about" */ '../views/search/search.vue')
      },
      {
        path: '/charge',
        name: 'charge',
        component: () => import(/* webpackChunkName: "about" */ '../views/charge/charge.vue'),
        children: [
          {
            path: '/charge/vip',
            name: "ChargeVip",
            component: () => import(/* webpackChunkName: "about" */ '../views/charge/ChargeVip.vue'),
          },
          {
            path: '/charge/flow',
            name: "ChargeFlow",
            component: () => import(/* webpackChunkName: "about" */ '../views/charge/ChargeFlow.vue'),
          },
          {
            path: '/charge/giftCard',
            name: "ChargeGiftCard",
            component: () => import(/* webpackChunkName: "about" */ '../views/charge/GiftCard.vue')
          }
        ]
      },
      {
        path: '/usercenter',
        name: 'usercenter',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/index.vue'),
      },
      {
        path: '/article',
        name: 'article',
        component: () => import(/* webpackChunkName: "about" */ '../views/article/index.vue'),
      },
      {
        path: '/userbill',
        name: 'userbill',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/user_bill/index.vue'),
      },
      {
        path: '/userinfo',
        name: 'userinfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/user_info/index.vue'),
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/login.vue')
  }
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
