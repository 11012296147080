import { OpenPrompt, PromptOCR,VerifyInput } from "@/api/api_chat"
import { Notify,Toast } from 'vant';
const state = {
    wx: null,            // 微信接口
    chat_token: null,    //聊天token
    jsApiList: [         //本页面需要的权限
        "chooseImage", "getLocalImgData",
        "startRecord", "onVoiceRecordEnd", "translateVoice", 'stopRecord'
    ],
    title:""//应用标题

}

const getters = {

}

const mutations = {//设置标题
    SET_WX_CLIENT(state, wx) {//设置微信调用接口
        state.wx = wx
    },
    SET_CHAT_TOKEN(state, {chat_token,title,response_type}) {//设置聊天TOKEN
        state.chat_token = chat_token
        state.title = title
        state.response_type = response_type
    }
}

const actions = {

    InitPrompt({ state, commit }, { prompt_id, url }) {//开始一个prompt

        return new Promise((reslove, reject) => {//获取页面信息

            OpenPrompt(prompt_id, url).then(({ data }) => {//初始化页面信息
                if (data['code'] === 20000) {//回调成功
                    commit('SET_CHAT_TOKEN', data)
                    // console.log(data)
                    wx.config({
                        ...data['wx_config'],
                        jsApiList: state.jsApiList, //['chooseImage'] 
                    });
                    wx.ready(() => {
                        commit('SET_WX_CLIENT', wx)
                    })
                    wx.error((err) => {//提示一下
                        reject(err)
                        Notify({ type: 'primary', message: '当前微信部分功能不可用' });
                    })
                    reslove(data)
                } else if (data['code'] === 40200) {
                    reject(data)
                } else if (data['code'] === 40300) {
                    reject(data)
                } else {
                    reject({})
                }
            })

        })

    },
    chooseImage({ state, commit }) {
        const error_msg = '当前微信版本不支持图片输入，您受累打打字吧'
        return new Promise((reslove, reject) => {
            if (!state.wx) {
                Notify({ type: 'primary', message: error_msg });
            }
            state.wx.chooseImage({
                count: 1, // 默认9
                sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success({ localIds }) {
                    wx.getLocalImgData({
                        localId: localIds[0], // 图片的localID
                        success: function (res) {
                            console.log(res)
                            var localData = res.localData;
                            reslove(localData)
                        },
                    });
                },
                fail(error) {
                    Notify({ type: 'primary', message: error_msg });
                    reject(error);
                },
            });
        })
    },
    Image2Text({ state }, image) {//图像转文字
        return new Promise((reslove, reject) => {
            if (!state.chat_token) {
                reject()
            }
            PromptOCR(state.chat_token, image).then(({ data }) => {
                reslove(data)
            }).catch(err => {
                reject(err)
            })

        })
    },
    voiceInput({ state }) {//开始录音
        return new Promise((reslove, reject) => {
            Toast({message:"按住说话...",duration:0})
            state.wx.startRecord();
            state.wx.onVoiceRecordEnd({
                // 录音时间超过一分钟没有停止的时候会执行 complete 回调
                complete: function (res) {
                    var localId = res.localId;
                    Toast.clear()
                    wx.translateVoice({
                        
                        localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: function ({translateResult}) {
                            
                            reslove(translateResult)
                        }
                    });

                }
            });


        })
    },
    vioceInputStop({ state }) {//主动停止
        return new Promise((reslove, reject) => {
            wx.stopRecord({
                success: function (res) {
                    Toast.clear()
                    var localId = res.localId;
                    wx.translateVoice({
                        localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: function ({translateResult}) {
                           
                            reslove(translateResult)
                        }
                    });
                }
            });
        })

    },
    VerifyUserInput({state},msg){//验证用户输入
        return new Promise((reslove,reject)=>{
            VerifyInput(state.chat_token,msg).then(({data})=>{
                if(data['ret']){
                    reslove(data)
                }else{
                    reject(data)
                }
            })
        })
        
    }
    

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}