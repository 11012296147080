<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: "app",
  // methods: {
  //   ...mapActions("auth", ["getUserInfo"]),
  // },
  // mounted(){//app拉起来以后

  //   //获取用户信息
  //   this.getUserInfo().then(({data})=>{
  //     console.log(data)
  //   })
  // }
};
</script>
<style lang="stylus"></style>
