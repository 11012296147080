import { get_user_info,get_email_verify_code } from "@/api/auth.js"
import { setToken } from '@/utils/auth'
import { parseUrl } from "@/utils/format.js"
const state = {
    user_info: null,
}
const getters = {
   
}

const mutations = {//设置标题

    SET_USER_INFO(stat, payload) {//获取用户信息
        stat.user_info = payload
    }
}

const actions = {

    getUserInfo({ commit }, token) {//获取用户信息
        if (token) {
            setToken(token)
            const { baseUrl, path, queryParams } = parseUrl(location.href)
            const url = `${baseUrl}${path}`
            window.location.href = url
        }
        return new Promise((reslove, reject) => {
            get_user_info().then(({ data }) => {//获取到用户信息
                commit('SET_USER_INFO', data)
                reslove(data)
            }).catch(reject)
        })
    },
    getEmailVerifyCode({ state }, email) {//发送验证码
        return new Promise((reslove, reject) => {
            //先验证一下是否存在未过期的的
            let email_verify_code = localStorage.getItem('email_verify_code')
            if (email_verify_code) {
                email_verify_code = JSON.parse(email_verify_code)
                let exp = new Date(email_verify_code['exp'])
                let now = new Date()
                if (now < exp) {
                    
                    reslove(email_verify_code)
                    return null
                }
            }
            console.log('runsend')
            get_email_verify_code(email).then(({ data }) => {//
                // let {token,exp} = data
                localStorage.setItem('email_verify_code', JSON.stringify(data))
                reslove(data)
            }).catch(reject)

        })

    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}