import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible'
import '@/assets/main.css'
import VueClipboard from 'vue-clipboard2'
import {Toast} from 'vant';
Vue.prototype.$toast = Toast
Vue.use(VueClipboard);
Vue.config.productionTip = false
Vue.use(Vant)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
