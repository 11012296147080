import { get_customer_packages,open_gift_card} from "@/api/recharge"


const state = {
    ActivatePackageId: null,
    OrderForm:{
        package_id:null,
        order_num:1,
    },
    packages: [//所有的方案
        /**
         * {
                "content": "VIP包月套餐，每月（按照31天计算）最大写作字数40万字。1、首次自购买日起生效，截止到31天后失效，未用尽的字数不可转结算2、购买多份为续费VIP时长3、已购买过的用户再次购买视为续费",
                "expires_in": 31,
                "id": 1,
                "package_type": 2,
                "status": 1,
                "word_size": 400000,
                "name": "普通VIP包月",
                "price": 100,
                "model_type": 1,
                "create_time": "2023-03-27T17:35:13"
            },
        */
    ],
    UserPackage: [//用户已有的包
        /**
         * {
                "word_size": 400000,
                "id": 1,
                "model_type": 1,
                "start_time": "2023-04-08T09:24:24",
                "create_time": "2023-04-08T09:24:24",
                "user_id": 1,
                "word_size_pack": 400000,
                "package_type": 2,
                "end_time": "2023-05-09T09:24:24",
                "manager_id": 0
            },
        */
    ],
    PackageTypeEnum: {//方案类型
        /**
         * "PACKAGE_FREE_TRAIL": {
                "value": 1,
                "name": "免费试用",
                "type": ""
            },
            "PACKAGE_VIP": {
                "value": 2,
                "name": "vip包月",
                "type": "warning"
            },
            "PACKAGE_FLOW": {
                "value": 255,
                "name": "流量包",
                "type": "info"
            }
        */
    },
}

const getters = {
    VipPackage(state) {//VIP包
        if (state.PackageTypeEnum['PACKAGE_VIP']) {
            const vipPackageType = state.PackageTypeEnum.PACKAGE_VIP.value
            return state.packages
                .filter(item => item.package_type === vipPackageType)
                .sort((a, b) => a.price - b.price)
        }
        return []

    },
    FlowPackage(state) {//流量包
        if(state.PackageTypeEnum['PACKAGE_FLOW']){
            const vipPackageType = state.PackageTypeEnum['PACKAGE_FLOW']['value']
        return state.packages
            .filter(item => item.package_type === vipPackageType)
            .sort((a, b) => a.price - b.price)

        }
        return []
    },
    UserVipEndTime(state) {//用户VIP包月到期时间
        if(!state.PackageTypeEnum['PACKAGE_VIP']){
            return new Date()
        }
        const vipPackageType = state.PackageTypeEnum.PACKAGE_VIP.value
        const userVipPackages = state.UserPackage.filter(
            item => item.package_type === vipPackageType
        )
        if (userVipPackages.length === 0) {
            
            return new Date()
        }
        const now = new Date()
        const latestPackage = userVipPackages.reduce((a, b) => {
            const aEndTime = new Date(a.end_time)
            const bEndTime = new Date(b.end_time)
            if (aEndTime < now || bEndTime < now) {
                return aEndTime < bEndTime && bEndTime > now ? b : a
            }
            return aEndTime > bEndTime ? a : b
        })
        return new Date(latestPackage.end_time)
    },
    ActivatePackage(state){//当前活跃的package
        const activate_pkg =  state.packages
        .filter(item => item.id === state.OrderForm.package_id)
        if(activate_pkg.length>0){
            return {...activate_pkg[0]}
        }
        return {}
    },
    

}

const mutations = {//设置标题
    SET_PACKAGES(state, packages) {
        state.packages = packages
    },
    SET_USER_PACKAGE(state, UserPackage) {
        state.UserPackage = UserPackage
    },
    SET_PACKAGE_TYPE_ENUM(state, PackageTypeEnum) {
        state.PackageTypeEnum = PackageTypeEnum
    },
    SET_ORDER_FORM(state,payload){//设置订单表
        // state.OrderForm = payload
        Object.assign(state.OrderForm,payload)
    }
}

const actions = {
    getPackages({ state, commit }) {//获取
        return new Promise((reslove, reject) => {
            get_customer_packages().then(({ data }) => {
                
                commit('SET_PACKAGES', data['packages'])
                commit('SET_USER_PACKAGE', data['UserPackage'])
                commit('SET_PACKAGE_TYPE_ENUM', data['PackageTypeEnum'])
                reslove(data)
            })
           
        })
    },
    openGiftCard({ state, commit,getters },code){//打开礼品卡查看信息
        return new Promise((reslove,reject)=>{
            open_gift_card(code).then(({data})=>{//
                const {ret,package_data,code} = data
                if(ret){//储值卡有效
                   reslove({package_data,code})
                }else{
                    reject()//无效的充值卡处理
                }
            })
        })
        
    }


}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}