import { get_wx_sdk_config } from "@/api/weixin"
const state = {

}

const getters = {

}

const mutations = {//设置标题

}

const actions = {
    getConfig({ state }, jsApiList) {//获取配置信息
        return new Promise((reslove, reject) => {
            get_wx_sdk_config().then(({ data }) => {//获得签名
                wx.config({
                    ...data,
                    jsApiList: jsApiList, //['chooseImage'] 
                });
                wx.ready(() => {
                    reslove(wx)
                })
                wx.error(reject)
            }).catch(reject)

        })
    },
    chooseImage({dispatch}) {//拍照或者选择照片
        return new Promise((reslove, reject) => {
            dispatch("getConfig", ["chooseImage","getLocalImgData"])
                .then((wx) => {
                    // 在成功获取配置信息后执行拍照或选择照片操作

                    wx.chooseImage({
                        count: 1, // 默认9
                        sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
                        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                        success({localIds}) {
                            console.log(localIds)
                            wx.getLocalImgData({
                                localId: localIds[0], // 图片的localID
                                success: function (res) {
                                  var localData = res.localData; 
                                  reslove(localData)
                                }
                            });
                        },
                        fail(error) {
                            // ...
                            reject(error);
                        },
                    });
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        })

    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}