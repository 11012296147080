import request from '@/utils/request'
export function wmp_user_login({code,state}) {//用户登陆
  return request({
    url: `/auth/wmp_user_login`,
    method: 'get',
    params:{code,state}
  })
}

export function get_user_info(){//获取用户信息
  return request({
    url: `/user/info`,
    method: 'get',
  }) 
}
export function update_user_info(data){//更新用户信息
  return request({
    url: `/user/info`,
    method: 'put',
    data
  }) 
}
export function get_email_verify_code(email){//验证邮箱
  return request({
    url: `/auth/verifyCode/email`,
    method: 'get',
    params:{'email':email}
  }) 
}
export function get_login_ercode(){//获取登陆二维码
  
  return request({
    url: `/auth/WmpLoginCode`,
    method: 'get',
  }) 
}

export function login_by_ercode(ticket){//获取登陆二维码
  
  return request({
    url: `/auth/TokenByWmpTicket`,
    method: 'get',
    params:{ticket}
  }) 
}