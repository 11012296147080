<template>
  <router-view />
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("auth", ["getUserInfo"]),
    ...mapActions('app',['getIndexConfig'])
  },
  
  mounted() {
    let token = this.$route.query["token"];
    let agent_id = this.$route.query["agent_id"];
    if(agent_id){
      window.localStorage.setItem('agent_id',agent_id)
    }
    let freetrial = this.$route.query["freetrial"] == "True";
    if (freetrial) {
      this.$notify({message:'您已成功获取500字体验',type:"success"});
    }
    this.getUserInfo(token).then((resp)=>{
      const login_from = window.localStorage.getItem('login_from')||null
      if(login_from){
        window.localStorage.removeItem('login_from')
        window.location.href = login_from
      }

    });
    this.getIndexConfig().then((resp) => {
      this.page_data = resp
    });
  },
  
};
</script>