import { get_static_file } from "@/api/app.js";
const state = {
    page_title: "",
    index_page: {},
}


const getters = {
    chat_default_url(state) {
        if (state.index_page['chat_default']) {
            const prompt_id = state.index_page['chat_default']['prompt_id']
            return `/chat?prompt_id=${prompt_id}`
        }
        return null
    }
}

const mutations = {//设置标题
    SET_PAGE_TITLE(state, title) {
        state.page_title = title || process.env.VUE_APP_TITLE
        document.title = state.page_title
    },
    SET_INDEX_PAGE(state, data) {
        state.index_page = data
    }
}

const actions = {
    getIndexConfig({ commit }) {//获取首页配置
        return new Promise((reslove, reject) => {
            get_static_file("/pages/index_page.json").then((resp) => {
                commit('SET_INDEX_PAGE', resp)
                reslove(resp)
            });
        })

    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}