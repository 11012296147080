
import { buildUrl } from "@/utils/format"
import request from '@/utils/request'

export function PromptCategory(catgory_ids,page_data){
    console.log(page_data)
    return request({
        url:'/prompt/list',
        method:'get',
        params:{
            categorys:catgory_ids.join(';'),
            ...page_data
        }
    })
}

export function OpenPrompt(prompt_id,url) {
    ///FeiyingWriter/prompt/{prompt_id}
    return request({
        url: `/prompt/${prompt_id}`,
        method: 'get',
        params:{
            url
        }
    })
}

export function PromptOCR(chat_token,img) {//ocr图片输入支持
    ///FeiyingWriter/prompt/{prompt_id}
    return request({
        url: `/prompt/ocr?chat_token=${chat_token}`,
        method: 'post',
        data:{
            'image':img
        }
    })
}
export function VerifyInput(chat_token,user_input) {//验证用户输入
    //prompt/verify/input
    return request({
        url: `/prompt/verify/input?chat_token=${chat_token}`,
        method: 'post',
        data:{
            'user_input':user_input
        }
    })
}


export function ChatPrompt(chat_token, message,api_id) {
    return new Promise((reslove, reject) => {//链接websocket
        let params = {}
        let url = ""
        if (api_id){
            url = buildUrl(process.env.VUE_APP_BASE_WS_API, `/chat/prompt`, {chat_token,api_id})
        }else{
            url = buildUrl(process.env.VUE_APP_BASE_WS_API, `/chat/prompt`, {chat_token})
        }
        
        const SocketClient = new WebSocket(url)
        SocketClient.onopen = function () {//成功打开发送命令
            //发送数据包
            SocketClient.send(JSON.stringify(message))
            reslove(SocketClient)
        }
        SocketClient.onerror = function (error) {//错误处理
            reject(error)
        }
    })

}
