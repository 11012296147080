import { ChatPrompt } from "@/api/api_chat.js"
import { JsonStreamParse, convertJsonPunctuation } from "@/utils/stream_data";

const state = {
    api_id: null
}

const getters = {

}

const mutations = {//设置标题
    SET_API_ID(state, api_id) {
        if (typeof api_id === 'number') {
            state.api_id = parseInt(api_id)
        }

    }
}

const actions = {

    chatSend({ state, commit }, { chat_token, response_type, message, onStream }) {//发送信息
        // console.log(chat_token)
        // console.log(message)
        const defaultMonitor = (data) => { console.log(data) }
        const StreamJson = new JsonStreamParse()
        let text_buffer = ''
        return new Promise((reslove, reject) => {
            //执行发送
            let monitor = defaultMonitor
            const monitor_map = {
                "<STREAM-START>": (data) => {//流监听器
                    
                    if (data === "<STREAM-END>") {//结束调用
                        console.log("streamend")
                        return 0
                    }
                    if (response_type == 'json') {
                        const data_obj = StreamJson.append(data)
                        if (data_obj && data_obj['type']) {//过滤器
                            onStream(StreamJson.buffer, data_obj)
                        }
                    }else{
                        text_buffer+=data
                        
                        onStream(text_buffer, {type:'text',msg:text_buffer})
                    }

                },
                "<STREAM-END>": defaultMonitor,
                "<SUMMARY-START>": (data) => {//回调结束
                    // console.log(data,"->",'summary')
                    const parser = new JsonStreamParse()
                    data = parser.commit(data)
                    if (data['api_id']) {
                        commit('SET_API_ID', data['api_id'])
                    }
                    reslove(data)
                },
                "<SUMMARY-END>": defaultMonitor,
                "<ERROR-START>": (err) => {//错误逻辑
                    // console.log(err,"->",'error')

                    const parser = new JsonStreamParse()
                    let data = parser.commit(err)

                    reject(data)

                },
                "<ERROR-END>": defaultMonitor
            }
            ChatPrompt(chat_token, message, state.api_id).then((SocketClient) => {
                SocketClient.onmessage = ({ data }) => {//创建一个websocket接收器
                    monitor(data)
                    if (data in monitor_map) {
                        monitor = monitor_map[data]
                    }

                }
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}