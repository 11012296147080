export function buildUrl(baseUrl, path, queryParams) {
    const params = new URLSearchParams(queryParams);
    const url = new URL(baseUrl + path);
    url.search = params;
    return url.toString();
}
export function parseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}`;
    const path = parsedUrl.pathname;
    const queryParams = parsedUrl.search;
    return { baseUrl, path, queryParams };
}