import axios from 'axios'
import { Notify } from 'vant';
import { getToken, removeToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Authorization'] = getToken()
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
   
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 20000) {
      
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {//登陆过期
        removeToken()
        let state = encodeURI(window.location.href.split("?")[0])
        let url = window.location.protocol+"//"+window.location.hostname+`/login?state=${state}`
        window.localStorage.setItem('login_from',location.href)
        window.location.href = url
      } else {
        Notify({ type: 'warning', message: res.detail || res.message || '未知异常',duration: 5 * 1000});
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Notify({ type: 'warning', message: error.message||error.detail|| '未知异常',duration: 5 * 1000});
    return Promise.reject(error)
  }
)

export default service
