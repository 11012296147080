/*
*写一个js类JsonStreamParse类
JsonStreamParse.append 方法每次接受一个字符，尝试补全json并输出补全后的object

j = `{
  "name": "John",
  "age": 30,
  "hobbies": ["reading", "music"],
  "address": {
    "city": "New York",
    "state": "NY"
  }
}`
for(let i=0;i<j.length;i++){
    reslut = JsonStreamParse.append(j[i])
    //i=0 result={}
    //i=1 result={}
    //i=2 result={'n':undefined}
    //i=3 result={'na':undefined}
    .....
    //i=9 result={'na':"J"}
    //i=12 result={'na':"John"}

}

**/

export class JsonStreamParse {
  constructor() {
    this.buffer = ''
    this.stack = []
    this.open_buffer = false // 是否打开缓存可以写入
    this.last_result = {}
  }
  append(char,filter) {
    
    if(char.length === 0) {//输入为空
      return self.last_result
    }
    if (char.length > 1) {//多个的话这一个字一个字添加
      
      for (let i in char) {
        this.append(char[i])
      }
      
    } else {
      if(filter){
        char = filter(char)
      }
      if (char === '{' || char === '[') {
        this.open_buffer = true
      }
      if (this.open_buffer) {
        this.buffer += char
      }
      const obj = this.tryParseJson()
      return obj
    }

  }
  commit(str, filter) {//一次性提交
    for (let i in str) {
      let c = str[i]
      // console.log(c)
      if (filter) {
        c = filter(c)//filter中可以进行类型转化
      }
      let ret = this.append(c)
      // console.log(this.buffer)
    }
    return this.last_result
  }
  tryParseJson() {
    try {
      const obj = JSON.parse(this.buffer)
      this.buffer = ''
      this.stack = []
      self.last_result = obj
      return obj
    } catch (e) {
      if (e instanceof SyntaxError) {
        // 语法错误，尝试修复输入字符串

        try {
          this.last_result = JSON.parse(this.completeJsonString(this.buffer))
        } catch (error) {
          // 没少要做的
          
        }
        return this.last_result
      } else {
        // 其他错误，抛出异常
        throw e
      }
    }
  }

  completeJsonString(inputStr) {
    const stack = []
    const symb_map = { '{': '}', '[': ']', '"': '"' }
    for (const c of inputStr) {
      if (c === '{' || c === '[') {
        stack.push(c)
      } else if (c === '}' || c === ']') {
        const bottom_symb = stack[stack.length - 1]
        if (symb_map[bottom_symb] === c) {
          stack.pop()
        }
      }
      if (c === '"') {
        const bottom_symb = stack[stack.length - 1]
        if (symb_map[bottom_symb] === c) {
          stack.pop()
        } else {
          stack.push(c)
        }
      }
    }
    for (const cp of stack.reverse()) {
      inputStr += symb_map[cp]
    }

    return inputStr
  }
}
export function convertJsonPunctuation(jsonStr) {
  // 将英文标点符号和对应的中文标点符号存储在对象中
  const punctuationMap = {
    ",": "，",
    ":": "：",
    "{": "｛",
    "}": "｝",
    "[": "【",
    "]": "】",
    "\"": "“",
    "'": "‘"
  };

  // 将 JSON 字符串中的英文标点符号替换为中文标点符号
  let result = '';
  let inString = false;
  for (let i = 0; i < jsonStr.length; i++) {
    const char = jsonStr[i];
    if (char === "\"" && !inString) {
      inString = true;
    } else if (char === "\"" && inString) {
      inString = false;
    }
    if (inString && char in punctuationMap) {
      result += punctuationMap[char];
    } else {
      result += char;
    }
  }

  return result;
}


export default {
  JsonStreamParse, convertJsonPunctuation
}
